(function () {
    $('.datepicker').datepicker({
        todayBtn: false,
        language: "id",
        autoclose: true,
        todayHighlight: true,
        format: 'dd/mm/yyyy'
    });

    $('.input-daterange').datepicker({
        todayBtn: false,
        language: "id",
        todayHighlight: true,
        autoclose: true,
        format: 'dd/mm/yyyy'
    });

    $('.input-daterange input').each(function () {
        $(this).datepicker("clearDates");
    });

    $('.input-monthrange input').each(function () {
        $(this).datepicker({
            format: "m/yyyy",
            viewMode: "months",
            minViewMode: "months",
            autoclose: true,
        });
    });

    $('#toggle-mmc').click(function () {
        $(this).removeClass('expanded');
        $(this).removeClass('collapsed');
        PixelAdmin.MainMenu.prototype.toggle();
        status = PixelAdmin.MainMenu.prototype._getMenuState();
        $(this).addClass(status);
    });

    $(".select2").select2({
        allowClear: true
    });

    $('.history').on('click', function () {
        if (document.referrer.indexOf(document.location.hostname) >= 0) {
            window.history.go(-1);
            return false;
        }
    });

    $(document).on('change', '.show_val', function () {
        var id = $(this).attr('id');
        var value = $(this).val();
        $('.' + id).hide();
        $('.' + id + '  .form-control').attr("disabled", true);
        $('#' + value + '  .form-control').attr("disabled", false);
        $('#' + value).show();
    });

    // disable enter button after input text type
    $(document).on('keyup keypress', 'form input', function (e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            return false;
        }
    });

    $(window).resize(function () {
        var element = $(".table-responsive");
        var tablewidth = element.width();

        if (tablewidth < 700) {
            element.addClass('forced');
        } else {
            element.removeClass('forced');
        }
    });


    $(".select_ajax").select2({
        ajax: {
            url: $('.select_ajax').data('url'), dataType: "json", delay: 250, data: function (e) {
                return {search: e.term, page: e.page}
            }, processResults: function (e, t) {
                return t.page = t.page || 1, {
                    results: $.map(e.data, function (e) {
                        var name = $('.select_ajax').data('name');
                        var value = $('.select_ajax').data('value');

                        return {text: e[name], id: e[value]}
                    }), pagination: {more: 30 * t.page < e.totals}
                }
            }, cache: !0
        }, minimumInputLength: 1, formatResult: function (e) {
            return e
        }, formatSelection: function (e) {
            return e
        }
    });

    $(".select_ajax").change(function () {
        var value = $(this).val();
        if (value) {
            $('.select_ajax_reset').show();
        } else {
            $('.select_ajax_reset').hide();
        }
    });

    $(".select_ajax_reset").click(function () {
        $(".select_ajax").val('').change();
    });

    $(document).on('change', '#paper_type', function () {
        // sembunyikan pajangan
        $('#placeholder').hide();
        $('#placeholder_gram').hide();
        $('#size_number').val(0);
        $('#paper_gram').val(0);

        // sembunyikan semua number
        $('.number_select').hide();
        $('.paper_gram').hide();
        // munculkan number sesuai dengan yang dipilih
        $('.selected_' + this.value).show();
    });

    $(document).on('change', '.number_select', function () {
        // sembunyikan pajangan
        $('#size_number').val($(this).val());
    });

    $(document).on('change', '.paper_gram', function () {
        // sembunyikan pajangan
        $('#paper_gram').val($(this).val());
    });


}).call(this);


